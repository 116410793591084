//TODO закрытие модала по esc

import "bootstrap";
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'
import "tether";
import "inputmask";
import { Diary } from "./modules/Diary";
import { App } from "./classes/App";
import * as legacyNew from "./legacy_new";
import legacy from "./legacy";
import { NotificationSystem } from "./modules/NotificationSystem";
import "../scss/main.scss";
import "jquery-ui/ui/widgets/datepicker";
import "../res/css/jquery-ui/themes/base/base.css";
import "../res/css/jquery-ui/themes/base/theme.css";
import "../res/css/jquery-ui/themes/base/datepicker.css";
import { AppConfigService } from "./classes/AppConfigService";

//TODO - убрать
window.globObj = {};

export const diary = new Diary();
window.diary = diary;

export const app = new App();
window.app = app;

window.legacy = legacy;

let lsState = localStorage.objectOperate(diary.getStateName()),
    $back = $("#back");

// if (lsState) {
//     diary.loadHistory(lsState);
// }

//TODO - отладить работу системы оповещений
diary.notifications = new NotificationSystem();

if (diary.notifications) {
    diary.notifications.send(3, "Внимание Педагогам!", "Выявлена ошибка - при введении тем не регистрировалась посещаемость. Необходимо проверить корректность данных и при необходимости пересохранить введенные темы!");
}
