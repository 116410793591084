const axios = require('axios').default;
import { Tooltip } from 'bootstrap';

/**
 *
 * @constructor Handlers
 */
export class Handlers {
    constructor() {
    }
    async excelExport(event) {
        event.preventDefault();
        let areaId = event.currentTarget.dataset.actionAreaId;
        let excelDataContainer = document.getElementById(areaId);
        let excelData = excelDataContainer.innerHTML;
        let formBody = [];
        let encodedValue = encodeURIComponent(excelData);
        formBody.push("data=" + encodedValue);
        formBody = formBody.join("&");

        fetch('/app/views/print_forms/excel.export.view.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: formBody
        })
            .then(response => response.blob())
            .then(blob => URL.createObjectURL(blob))
            .then(url => {
                window.open(url, '_blank');
                URL.revokeObjectURL(url);
            });
    }

    unCheckOtherBoxes(event) {
        let thisBox = event.target;
        if (thisBox.dataset?.checkboxGroup != 'group') {
            return;
        }
        let id = thisBox.dataset.checkboxGroupId;
        let grouopBoxes = document.querySelectorAll('[data-checkbox-group-id="' + id + '"]');

        grouopBoxes.forEach(element => {
            if (element != thisBox) {
                element.checked = false;
            }
            if (!thisBox.checked) {
                thisBox.checked = true;
            }
        });
    }

}


